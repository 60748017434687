<template>
  <div class="min-h-contain">
    <div v-if="stillLoading" class="loading-page">
      <vue-simple-spinner size="large" message="Fetching Data..."></vue-simple-spinner>
    </div>
    <div v-else>
      <div class="mb-6 flex justify-between items-center">
        <h1 class="text-3xl font-bold">{{ $route.meta.label }}</h1>
        <Button :buttonText="'Update'" @action="save" :disabled="isSaving" />
      </div>
    </div>
    <div class="shadow-small rounded-lg bg-white mb-6 p-6">
      <div class="mb-6">
        <TextField type="text" label="Customer Name" :borderEnabled="true" placeholder="Enter Customer Name" v-model="field.customerName" :error="validationErrors.customerName" />
      </div>
      <div class="mb-6 grid grid-cols-3 gap-6">
        <TextField type="text" label="Client Code" :borderEnabled="true" placeholder="Enter Client Code" v-model="field.clientCode" :error="validationErrors.clientCode" />
        <TextField type="text" label="Sign Key" :borderEnabled="true" placeholder="Enter Sign Key" v-model="field.signKey" :error="validationErrors.signKey" />
        <TextField type="text" label="Slug" :borderEnabled="true" placeholder="Enter Slug" v-model="field.slug" :error="validationErrors.slug" />
      </div>
      <div class="mb-6 grid grid-cols-2 gap-6">
        <TextField
          type="text"
          label="Customer Support Number"
          :borderEnabled="true"
          placeholder="Enter Customer Support Number"
          v-model="field.customerSupportNumber"
          :error="validationErrors.customerSupportNumber"
        />
        <TextField
          type="text"
          label="Customer Support Email"
          :borderEnabled="true"
          placeholder="Enter Customer Support Email"
          v-model="field.customerSupportEmail"
          :error="validationErrors.customerSupportEmail"
        />
      </div>
      <div class="mb-6">
        <TextField type="number" label="Sequence" :borderEnabled="true" placeholder="Enter Sequence" v-model="field.sequence" :error="validationErrors.sequence" />
      </div>
      <div class="pl-3 mb-6">
        <div class="text-xs font-semibold">Customer Status</div>
        <div class="flex gap-4 pt-3 pb-3">
          <div class="flex">
            <input type="radio" :name="`1`" value="1" v-model="field.status" />
            <div class="text-sm ml-2">Active</div>
          </div>

          <div class="flex">
            <input type="radio" :name="`0`" value="0" v-model="field.status" />
            <div class="text-sm ml-2">Inactive</div>
          </div>
        </div>
        <div class="text-xs">If inactive, customer will not be shown on Redeem Page</div>
      </div>
      <div class="pl-3 mb-6">
        <div class="text-xs font-semibold mb-3">Image</div>
        <div class="image-container">
          <img :src="field.image" alt="image" />
          <input type="file" ref="fileInput" style="display: none" @change="handleFileUpload" />
          <Button :buttonText="'Change'" @action="openFileExplorer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormValidator from '@/libs/FormValidator'
export default {
  components: {
    Button: () => import(/* webpackChunkName: "Button" */ '@/components/Button/Button'),
    Dropdown: () => import(/* webpackChunkName: "Dropdown" */ '@/components/Dropdown/Dropdown'),
    TextField: () => import(/* webpackChunkName: "TextField" */ '@/components/Form/TextField'),
    Modal: () => import(/* webpackChunkName: "Modal" */ '@/components/Modal/Modal'),
    Search: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Search'),
    Alert: () => import(/* webpackChunkName: "Icons" */ '@/components/Icons/Alert')
  },
  name: 'RolesForm',
  data() {
    return {
      stillLoading: false,
      isSaving: false,
      file: null,
      field: {
        customerName: '',
        clientCode: '',
        signKey: '',
        slug: '',
        customerSupportNumber: '',
        customerSupportEmail: '',
        status: '1',
        sequence: '',
        image: ''
      },
      validationErrors: {}
    }
  },
  computed: {},
  mounted() {
    this.initData()
  },
  methods: {
    initData() {
      this.stillLoading = true
      this.$store
        .dispatch('credentials/GET_CREDENTIAL_DETAIL', {
          id: this.$route.params.id
        })
        .then((resp) => {
          const data = resp.data.data
          this.field.customerName = data.name || ''
          this.field.clientCode = data.clientCode || ''
          this.field.signKey = data.signKey || ''
          this.field.slug = data.slug || ''
          this.field.customerSupportNumber = data.waNumber || ''
          this.field.customerSupportEmail = data.email || ''
          this.field.sequence = typeof data.sequence === 'number' ? data.sequence.toString() : ''
          this.field.status = data.status === 'Active' ? '1' : '0'
          this.field.image = data.logo
          this.stillLoading = false
        })
    },
    save() {
      const isValid = this.isValid(this.field)
      if (isValid) {
        const formData = new FormData()
        formData.append('name', this.field.customerName)
        formData.append('clientCode', this.field.clientCode)
        formData.append('signKey', this.field.signKey)
        formData.append('slug', this.field.slug)
        formData.append('waNumber', this.field.customerSupportNumber)
        formData.append('email', this.field.customerSupportEmail)
        formData.append('status', this.field.status === '1' ? 'Active' : 'Inactive')
        formData.append('sequence', this.field.sequence.length > 0 ? Number(this.field.sequence) : null)
        if (this.file) {
          formData.append('logo', this.file)
        }

        const url = `/api/customers/credential/${this.$route.params.id}`

        this.isSaving = true
        axios
          .put(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(() => {
            this.isSaving = false
          })
          .catch((error) => {
            this.isSaving = false
            const errorMessage = error.errors.error
            if (errorMessage.toLowerCase().includes('sequence')) {
              this.validationErrors = { sequence: errorMessage }
            }
          })
      }
    },
    isValid(fields) {
      const isEmail = (email) => {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailPattern.test(email)
      }

      const validationRules = [
        {
          fieldName: 'customerName',
          required: true,
          errorMessage: 'Customer Name is required'
        },
        {
          fieldName: 'status',
          required: true,
          errorMessage: 'status is required'
        },
        {
          fieldName: 'customerSupportEmail',
          custom: (email) => {
            if (email.trim().length === 0) return true
            return isEmail(email)
          },
          errorMessage: 'Email is not valid'
        }
      ]

      const validator = new FormValidator(validationRules)
      const validationErrors = validator.validateForm(fields)
      this.validationErrors = validationErrors
      return Object.keys(validationErrors).length === 0
    },
    openFileExplorer() {
      // Trigger click event of the file input element
      this.$refs.fileInput.click()
    },
    handleFileUpload(event) {
      // Handle file upload logic here
      const file = event.target.files[0]
      const tempUrl = URL.createObjectURL(file)
      this.file = file
      this.field.image = tempUrl
    }
  }
}
</script>
<style scoped>
.image-container {
  width: 200px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

[type='radio']:checked + label:before {
  content: '';
  height: 20px;
  border: 2px solid #3b82f6;
}

[type='radio']:checked + label:after,
[type='radio']:not(:checked) + label:after {
  background: #3b82f6;
}
</style>
