class FormValidator {
  validationRules

  constructor(validationRules) {
    this.validationRules = validationRules
  }

  validateForm(formData) {
    const errors = {}

    for (const rule of this.validationRules) {
      if (rule.fieldName in errors) continue

      if (!(rule.fieldName in formData)) {
        throw Error(`Expected field ${rule.fieldName} but not found in form data`)
      }

      const value = formData[rule.fieldName]
      const errorMessage = this.validateField(rule.fieldName, value, rule)
      if (errorMessage) {
        errors[rule.fieldName] = errorMessage
      }
    }

    return errors
  }

  validateField(fieldName, value, rules) {
    if (rules.required && !value.trim()) {
      return rules.errorMessage || `${fieldName} is required`
    }

    if (rules.minLength && value.length < rules.minLength) {
      return rules.errorMessage || `${fieldName} must be at least ${rules.minLength} characters long`
    }

    if (rules.maxLength && value.length > rules.maxLength) {
      return rules.errorMessage || `${fieldName} must be at most ${rules.maxLength} characters long`
    }

    if (rules.pattern && !rules.pattern.test(value)) {
      return rules.errorMessage || `Invalid ${fieldName}`
    }

    if (rules.custom && !rules.custom(value)) {
      return rules.errorMessage || `Invalid ${fieldName}`
    }

    return undefined
  }
}

export default FormValidator
