var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "min-h-contain"
  }, [_vm.stillLoading ? _c('div', {
    staticClass: "loading-page"
  }, [_c('vue-simple-spinner', {
    attrs: {
      "size": "large",
      "message": "Fetching Data..."
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "mb-6 flex justify-between items-center"
  }, [_c('h1', {
    staticClass: "text-3xl font-bold"
  }, [_vm._v(_vm._s(_vm.$route.meta.label))]), _c('Button', {
    attrs: {
      "buttonText": 'Update',
      "disabled": _vm.isSaving
    },
    on: {
      "action": _vm.save
    }
  })], 1)]), _c('div', {
    staticClass: "shadow-small rounded-lg bg-white mb-6 p-6"
  }, [_c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Customer Name",
      "borderEnabled": true,
      "placeholder": "Enter Customer Name",
      "error": _vm.validationErrors.customerName
    },
    model: {
      value: _vm.field.customerName,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "customerName", $$v);
      },
      expression: "field.customerName"
    }
  })], 1), _c('div', {
    staticClass: "mb-6 grid grid-cols-3 gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Client Code",
      "borderEnabled": true,
      "placeholder": "Enter Client Code",
      "error": _vm.validationErrors.clientCode
    },
    model: {
      value: _vm.field.clientCode,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "clientCode", $$v);
      },
      expression: "field.clientCode"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Sign Key",
      "borderEnabled": true,
      "placeholder": "Enter Sign Key",
      "error": _vm.validationErrors.signKey
    },
    model: {
      value: _vm.field.signKey,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "signKey", $$v);
      },
      expression: "field.signKey"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Slug",
      "borderEnabled": true,
      "placeholder": "Enter Slug",
      "error": _vm.validationErrors.slug
    },
    model: {
      value: _vm.field.slug,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "slug", $$v);
      },
      expression: "field.slug"
    }
  })], 1), _c('div', {
    staticClass: "mb-6 grid grid-cols-2 gap-6"
  }, [_c('TextField', {
    attrs: {
      "type": "text",
      "label": "Customer Support Number",
      "borderEnabled": true,
      "placeholder": "Enter Customer Support Number",
      "error": _vm.validationErrors.customerSupportNumber
    },
    model: {
      value: _vm.field.customerSupportNumber,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "customerSupportNumber", $$v);
      },
      expression: "field.customerSupportNumber"
    }
  }), _c('TextField', {
    attrs: {
      "type": "text",
      "label": "Customer Support Email",
      "borderEnabled": true,
      "placeholder": "Enter Customer Support Email",
      "error": _vm.validationErrors.customerSupportEmail
    },
    model: {
      value: _vm.field.customerSupportEmail,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "customerSupportEmail", $$v);
      },
      expression: "field.customerSupportEmail"
    }
  })], 1), _c('div', {
    staticClass: "mb-6"
  }, [_c('TextField', {
    attrs: {
      "type": "number",
      "label": "Sequence",
      "borderEnabled": true,
      "placeholder": "Enter Sequence",
      "error": _vm.validationErrors.sequence
    },
    model: {
      value: _vm.field.sequence,
      callback: function callback($$v) {
        _vm.$set(_vm.field, "sequence", $$v);
      },
      expression: "field.sequence"
    }
  })], 1), _c('div', {
    staticClass: "pl-3 mb-6"
  }, [_c('div', {
    staticClass: "text-xs font-semibold"
  }, [_vm._v("Customer Status")]), _c('div', {
    staticClass: "flex gap-4 pt-3 pb-3"
  }, [_c('div', {
    staticClass: "flex"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.field.status,
      expression: "field.status"
    }],
    attrs: {
      "type": "radio",
      "name": "1",
      "value": "1"
    },
    domProps: {
      "checked": _vm._q(_vm.field.status, "1")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.field, "status", "1");
      }
    }
  }), _c('div', {
    staticClass: "text-sm ml-2"
  }, [_vm._v("Active")])]), _c('div', {
    staticClass: "flex"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.field.status,
      expression: "field.status"
    }],
    attrs: {
      "type": "radio",
      "name": "0",
      "value": "0"
    },
    domProps: {
      "checked": _vm._q(_vm.field.status, "0")
    },
    on: {
      "change": function change($event) {
        return _vm.$set(_vm.field, "status", "0");
      }
    }
  }), _c('div', {
    staticClass: "text-sm ml-2"
  }, [_vm._v("Inactive")])])]), _c('div', {
    staticClass: "text-xs"
  }, [_vm._v("If inactive, customer will not be shown on Redeem Page")])]), _c('div', {
    staticClass: "pl-3 mb-6"
  }, [_c('div', {
    staticClass: "text-xs font-semibold mb-3"
  }, [_vm._v("Image")]), _c('div', {
    staticClass: "image-container"
  }, [_c('img', {
    attrs: {
      "src": _vm.field.image,
      "alt": "image"
    }
  }), _c('input', {
    ref: "fileInput",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.handleFileUpload
    }
  }), _c('Button', {
    attrs: {
      "buttonText": 'Change'
    },
    on: {
      "action": _vm.openFileExplorer
    }
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }